import * as React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebaseui/dist/firebaseui.css";
import { BasicLayout } from "../../components/layout";
import SEO from "../../components/seo/seo";
import { initialzeFirebase } from "../../core/initialize-firebase";

const SignIn: React.FC = () => {
  React.useEffect(() => {
    if (window && typeof window !== undefined) {
      initialzeFirebase();

      const firebaseui = require("firebaseui");

      const ui = new firebaseui.auth.AuthUI(firebase.auth());
      ui.start("#firebaseui-auth-container", {
        signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
        signInSuccessUrl: "/internal/topics/",
      });
    }
  }, []);

  return (
    <BasicLayout>
      <SEO title="Sign in" meta={[{ name: "robots", content: "noindex" }]} />

      <div id="firebaseui-auth-container"></div>
    </BasicLayout>
  );
};

export default SignIn;
